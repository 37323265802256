"use strict";

/**
 * Created by kayran on 6/22/17.
 */

window.startIntercom = function startIntercom(params) {
  var intercomSettings = {
    app_id: 'cuuvmvgm',
    name: params.user.name,
    email: params.user.email,
    created_at: params.user.created_at,
    user_group: params.user.user_group,
    payment_kind: params.user.payment_kind,
    user_hash: params.user.hash
  };
  if ('account' in params) {
    intercomSettings.acc_id = params.account.id;
    intercomSettings.acc_name = params.account.name;
    intercomSettings.acc_group = params.account.account_group;
  }
  if ('universe' in params) {
    intercomSettings.uni_id = params.universe.id;
    intercomSettings.uni_name = params.universe.name;
  }
  if ('cluster' in params) intercomSettings.cluster_id = params.cluster;
  if ('trial_expire' in params.user) intercomSettings.trial_expire = params.user.trial_expire;
  (function () {
    var w = window;
    var activeSetting = function activeSetting() {
      var ic = w.Intercom;
      ic('reattach_activator');
      ic('update', intercomSettings);
    };
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      activeSetting();
    } else {
      var d = document;
      var i = function i() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      w.addEventListener('load', function l() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/cuuvmvgm';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        activeSetting();
      }, false);
    }
  })();
};